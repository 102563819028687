//
// _form-control.scss
//

.form-icon {
  position: relative;
  .form-control-icon {
    padding-left: $input-padding-x * 3;
    position: relative;
  }
  .template_icon {
    border-bottom: 2px solid gray !important;
  }
  svg {
    position: absolute;
    top: 35%;
    // top: 0px;
    // bottom: 0px;
    // left: 18px;
    // display: flex;
    // align-items: center;
  }
  &.right {
    .form-control-icon {
      padding-right: $input-padding-x * 3;
      padding-left: $input-padding-x;
      position: relative;
    }
    i {
      left: auto;
      right: 18px;
    }
  }
}

.is-required {
  // border-color: #f06548;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='10' width='7.5' viewBox='0 0 384 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23dc3545' d='M192 32c17.7 0 32 14.3 32 32V199.5l111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V312.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5V64c0-17.7 14.3-32 32-32z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  // background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

[data-layout-mode="dark"] {
  .form-select {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")
    );
  }
}
