.audience-table {
	& > :not(caption) > * > * {
		padding: 0.2rem 0.6rem;
	}

	.filter-div {
		select {
			width: -webkit-fill-available !important;
		}
	}

	th:first-child,
	th:nth-child(2),
	th:nth-child(3),
	td:first-child,
	td:nth-child(2),
	td:nth-child(3) {
		position: sticky !important;
		z-index: 3;
	}

	td:first-child,
	td:nth-child(2),
	td:nth-child(3) {
		background-color: #fff;
	}

	th:first-child {
		left: 0;
		width: 30px !important;
	}
	td:first-child {
		left: 0;
		width: 30px !important;
	}

	th:nth-child(2),
	td:nth-child(2) {
		left: 30px;
	}
	th:nth-child(3),
	td:nth-child(3) {
		left: 100px;
	}
	tbody tr:last-child{
		padding-bottom:14px;
	}
}
