// .question_bank_tooltip + .arrow {
//   &::after,
//   &::before {
//     background-color: $primary;
//     color: $primary;
//     border-color: $primary;
//   }
// }

[data-placement="top"] + .tooltip > .tooltip-arrow {
  border-top-color: #8447cf;
}

[data-placement="right"] + .tooltip > .tooltip-arrow {
  border-right-color: #8447cf;
}

[data-placement="bottom"] + .tooltip > .tooltip-arrow {
  border-bottom-color: #8447cf;
}

[data-placement="left"] + .tooltip > .tooltip-arrow {
  border-left-color: #8447cf;
}
