.survey_initiate {
  margin-top: 4rem;
  padding: 0.5rem calc(#{$grid-gutter-width} / 2) 1rem
    calc(#{$grid-gutter-width} / 2);

  .template_view_row {
    .template_browse_card {
      h5 {
        color: #4e4e4e;
      }
    }

    .suggested_search_list {
      color: gray;
      span {
        cursor: pointer;
      }
    }
  }
  .initiate-form-check {
    svg {
      left: 0;
    }
    .clear_search_icon {
      position: absolute;
      top: 25%;
      right: 0;
    }
    .form-control-icon {
      padding-left: 2rem;
    }
  }
  .survey-creation-list {
    li {
      box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -webkit-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -moz-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      border: 1px solid #fff;
      cursor: pointer;
      a {
        i {
          font-size: 14px;
        }
      }
      &:hover {
        border: 1px solid $primary;
      }
    }
  }
  .survey-filter {
    color: #6b787f;
    border: 1px solid #6b787f !important;
    background-color: #fff;
    padding: 0 24px;
    font-size: 15px;
    min-width: 140px;
    height: 40px;
    border: 0px;
    border-radius: 5px;
  }
  .survey-fav {
    color: #6b787f;
    border: 1px solid #6b787f;
    background-color: #fff;
    padding: 0 24px;
    font-size: 15px;
    min-width: 140px;
    height: 40px;
    border-radius: 5px;
  }

  .survey_view_filters .survey-filter-button.active {
    color: #fff;
    border-color: #6b787f;
    background-color: #6b787f;
  }

  .survey-view-pagination {
    ul {
      margin: 0.5rem auto;
    }
  }
  .page-link {
    border-color: $primary;
  }
  .page-link:hover {
    background-color: $primary;
    color: #ffffff;
  }
  .team-row {
    .tooltip {
      .tooltip-arrow::before {
        border-top-color: #fff !important;
      }

      .tooltip-inner {
        // position: relative;
        // display: flex;
        // flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: var(--vz-card-bg);
        // background-clip: border-box;
        border: none;
        box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
        border-radius: 0;
        color: black;
        text-align: left;
        padding: 1rem;
      }
    }
  }

  .template_simple_bar {
    max-height: 53vh;
    .team-box-template {
      div {
        width: 100%;
        height: 100%;
      }
    }
    /* Styles for small height screens (up to 480px) */
    @media only screen and (max-height: 480px) {
      /* Your styles for small height screens go here */
    }

    /* Styles for medium height screens (481px to 768px) */
    @media only screen and (min-height: 481px) and (max-height: 768px) {
      /* Your styles for medium height screens go here */
    }

    /* Styles for large height screens (769px and above) */
    @media only screen and (min-height: 769px) {
      max-height: 68vh;
      // .team-box {
      //   & > div {
      //     height: 90%;
      //   }
      // }
    }
  }
}
