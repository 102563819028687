//
// _authentication.scss
//

.auth-page-wrapper {
  .auth-page-content {
    // padding-bottom: $footer-height;
    position: relative;
    z-index: 2;
    width: 100%;

    .left_side_auth {
      background-image: url("/src/assets/images/6072038_3145241.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .right_side_auth {
      .form-label {
        color: #000000da;
        font-weight: 500;
      }
      .terms-checkbox {
        border: 1px solid #000000da;
        border-radius: 0.15em;
      }
    }

    .PhoneInput {
      .PhoneInputCountrySelectArrow {
        display: none;
      }
      .PhoneInputInput {
        flex: auto;
        min-width: auto;
        border: none;
      }
    }

    .nav {
      .nav-link {
        &.active,
        &.done {
          background-color: $smart-green;
          color: $white;
        }
      }
    }
  }
  .auth-survey-creation {
    width: 100%;
  }
  .footer {
    left: 0;
    background-color: transparent;
    color: $body-color;
  }
}

// .auth-one-bg-position {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	width: 100%;
// 	height: -webkit-fill-available;

// 	@media (max-width: 575.98px) {
// 		height: 280px;
// 	}
// }

.auth-one-bg {
  background: linear-gradient(to right, darken($info, 40%), $info);
  opacity: 0.9;

  .bg-overlay {
    // background: linear-gradient(to right, darken($info, 40%), $info);
    opacity: 0.9;
  }

  .shape {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    > svg {
      width: 100%;
      height: auto;
      fill: var(--#{$variable-prefix}body-bg);
    }
  }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ecb6";
    }
  }
}

.particles-js-canvas-el {
  position: relative;
}
