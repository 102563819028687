.custom_carousel {
  margin: auto;

  .carousel-indicators {
    position: static;
    margin-bottom: 0;
    /* Your general indicator styles go here */

    button {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      border-top: none;
      border-bottom: none;
    }
  }
  .carousel-inner {
    position: static;

    .carousel-item {
      // height: 80vh;
      .carousel-caption {
        position: static;
        h3 {
          color: white;
        }
        p {
          margin-bottom: 0;
        }
      }
      img {
        height: 60vh;
      }
    }
  }
}
