//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-semibold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: var(--#{$variable-prefix}light);
  }

  > thead {
    border-color: $table-border-color;
  }
  > :not(:first-child) {
    border-top-width: $table-border-width;
  }
}

// .sticky {
// 	overflow: scroll;
// 	.th,
// 	.footer {
// 		position: sticky;
// 		z-index: 1;
// 		width: fit-content;
// 	}

// 	.th {
// 		top: 0;
// 		box-shadow: 0px 3px 3px #ccc;
// 	}

// 	.footer {
// 		bottom: 0;
// 		box-shadow: 0px -3px 3px #ccc;
// 	}

// 	.tbody {
// 		position: relative;
// 		z-index: 0;
// 	}

// 	[data-sticky-td] {
// 		position: sticky;
// 	}

// 	[data-sticky-last-left-td] {
// 		box-shadow: 2px 0px 3px #ccc;
// 	}

// 	[data-sticky-first-right-td] {
// 		box-shadow: -2px 0px 3px #ccc;
// 	}
// }

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
.survey-table {
  thead {
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.75);
  }
  .card-header-dropdown {
    .dropdown-item {
      &:hover {
        color: #0ab39c;
        transition: transform 0.2s;
        a:not(.upgrade_a),
        div {
          color: $polynesian-blue-500 !important;
          transform: scale(1.1);
        }
      }
    }
  }
}

// card table

.table-card {
  margin: (-$card-spacer-y) (-$card-spacer-x);

  th,
  td {
    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
      text-align: center;
    }
  }

  .table > :not(:first-child) {
    border-top-width: $table-border-width;
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    &.table {
      > thead {
        border-color: $value !important;
      }
    }
  }
  .table-#{$color} {
    &.table {
      > thead {
        border-bottom-color: lighten($value, 10%) !important;
      }
    }
  }
}

.table {
  .member-active-switch {
    .form-check-input:checked {
      background-color: #0ab39c;
      border-color: #0ab39c;
    }
  }
  .form-check {
    padding-left: 0px;
    margin-bottom: 0px;
    .form-check-input {
      margin-left: 0px;
      margin-top: 0px;
      float: none;
      vertical-align: middle;
    }
  }
}

[data-layout-mode="dark"] {
  .table-light {
    background-color: var(--#{$variable-prefix}light);
    color: var(--#{$variable-prefix}body-color);
    th,
    td {
      background-color: var(--#{$variable-prefix}light);
    }
  }
}
