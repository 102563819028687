.content-editor-view {
  .ql-toolbar {
    display: none;
  }
  .ql-container {
    // border-top: 1px solid #ccc !important;
    // border-radius: 4px;
    border: none !important;
  }
}
