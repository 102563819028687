//
// _modal.scss
//

.container-modal {
  display: flex;
  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 1024 1024" fill="%2300529e" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="%2300529e"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z" fill=""></path></g></svg>');
  }
  & > div {
    width: 100%;
    overflow: hidden; /* Ensure content doesn't overflow */
    position: absolute;
    left: -100%;
  }
  .active {
    animation: slideLeft 2s forwards;
    width: 100%;
    position: relative;
  }
  .previous {
    animation: slideRight 2s forwards;
  }

  @keyframes slideLeft {
    0% {
      left: 100%;
    }
    100% {
      left: 0; /* Slide out to the left */
    }
  }

  @keyframes slideRight {
    0% {
      left: 0;
    }
    100% {
      left: 100%; /* Slide out to the right */
    }
  }

  @keyframes fadeIn {
    90% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
}

.modal-title {
  font-weight: $font-weight-semibold;
}

.modal-dialog:not(.modal-dialog-scrollable) {
  .modal-header {
    padding-bottom: 0;

    .btn-close {
      margin-top: -$modal-header-padding;
    }
  }

  .modal-footer {
    padding-top: 0;
  }
}

.modal {
  .modal-dialog {
    max-width: 600px;
  }
  &.fadeInRight {
    .modal-dialog {
      opacity: 0;
      transform: translateX(20%);
      transition: all 0.3s ease-in-out;
    }

    &.show {
      .modal-dialog {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &.fadeInLeft {
    .modal-dialog {
      animation: fadeInLeft 0.3s ease-in-out;
      transform: translate(-50%, 0);
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }

  &.fadeInUp {
    .modal-dialog {
      animation: fadeInUp 0.3s ease-in-out;
      transform: translate(0, 30%);
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }

  &.flip {
    perspective: 1300px;

    .modal-dialog {
      opacity: 0;
      transform: rotateY(-70deg);
      transition: all 0.3s;
    }

    &.show {
      .modal-dialog {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }

  &.zoomIn {
    .modal-dialog {
      opacity: 0;
      transform: scale(0.7);
      transition: all 0.3s ease;
    }

    &.show {
      .modal-dialog {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.modal-dialog-right {
  margin-right: $modal-dialog-margin-y-sm-up;
}

// modal-dialog-bottom
.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - #{$modal-dialog-margin-y-sm-up});

  @media (min-width: 576px) {
    min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});
  }
}

.modal-dialog-bottom-right {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - #{$modal-dialog-margin-y-sm-up});
  margin-right: $modal-dialog-margin-y-sm-up;

  @media (min-width: 576px) {
    min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@media only screen and (max-width: 1500px) {
  .modal-dialog {
    max-width: 767px;
  }
}

// antlere dev defined

.industry_modal {
  .survey-creation-list {
    li {
      box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -webkit-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -moz-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      border: 1px solid #fff;
      cursor: pointer;
      a {
        i {
          font-size: 14px;
        }
      }
      &:hover {
        border: 1px solid $primary;
      }
    }
    .industry_list_item {
      box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -webkit-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      -moz-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
      border: 1px solid #fff;
      cursor: pointer;
      a {
        i {
          font-size: 14px;
        }
      }
      &:hover {
        border: 1px solid $primary;
      }
    }

    .form-check-label {
      border: 1px solid $primary;
      &::before {
        content: "\eb80";
        font-family: "remixicon";
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 16px;
        color: #405189;
      }
    }
  }
}

.surveyModal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 83vw !important;

  .modal-body {
    padding: 0 !important;
    .add-section-div {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        display: block;
        content: "";
        width: 85%;
        height: 1px;
        background-color: rgba(112, 112, 112, 0.2);
      }
      span {
        background-color: rgba(112, 112, 112, 0.4);
      }
    }
  }
  .modal-content {
    width: 80%;
    border: none;
    position: relative;
    .modal-header {
      justify-content: center;
      h5 {
        color: #fff;
      }
      .modal-title {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn-close {
        position: absolute;
        right: 25px;
        background: none !important;
        width: 5%;
        height: 0.1em;
        border-radius: 0%;
        opacity: 1;
      }
    }
  }
}

.accessModal {
  max-width: 600px !important;
  height: auto;
  .modal-body {
    padding: 1.25rem !important;
  }
}
.modal_preliminary_details {
  .survey-modal-info-card {
    color: gray;
    box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.75);

    .preliminary_data_skeleton {
      // Extra large devices (large desktops, 1200px and up)
      @media (min-width: 1200px) {
        height: 38vh;
      }
      @media (min-width: 1800px) {
        height: 48vh;
      }
    }
  }
  .nav-pills {
    position: relative;
    li > a {
      color: $polynesian-blue-500;
    }
    .nav-item {
      width: 51%;
      background-color: $polynesian-blue-200;

      &:first-of-type {
        position: absolute;
        clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
      }
      &:last-of-type {
        position: absolute;
        right: 0px;
        clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
        .nav-link {
          padding-left: 2rem;
        }
      }
      .nav-link {
        position: relative;
        border-radius: 0px;
      }
      .active {
        background-color: $polynesian-blue-500 !important;
      }
    }
  }

  .survey_details_form_1 {
    // width: 97%;
    .customerFileBtn {
      display: flex;
      justify-content: center;

      margin: auto;
      margin-top: 20px;
      & > label {
        padding: 0.7rem 0.9rem;
        font-weight: 400;
        font-size: 14px;
      }
      input[type="file"] {
        padding: 0.9rem 0rem;
        &::-webkit-file-upload-button {
          display: none;
        }
        &::file-selector-button {
          display: none;
        }
        label:hover {
          background-color: #dde0e3;
          cursor: pointer;
        }
      }
    }
    .form-switch {
      padding: 0.5rem 0.7rem;
      color: #333;
      .form-label {
        font-size: 0.8125rem;
        font-weight: 400;
      }
    }
    .logo-input {
      & > label {
        padding: 1.5rem 0.9rem 0.1rem;
        font-weight: 400;
        font-size: 0.8125;
        cursor: pointer;
        .input_title {
          left: 15px;
          // color: #000;
          font-weight: 600;
          // border-bottom: 1px solid $primary;
        }
      }
      & > .form-control {
        height: calc(2.5rem + 2px);
        padding: 0.7rem 0.9rem 0rem 0.9rem;
        font-size: 0.8125;
        font-weight: 400;
      }
    }
    .form-floating {
      & > label {
        padding: 0.7rem 0.9rem 1rem 0.9rem;
        font-weight: 400;
        font-size: 12px;
      }
      & > .form-control {
        height: calc(2.5rem + 2px);
        padding: 0.7rem 0.9rem 0rem 0.9rem;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .accordion {
    .accordion-header {
      button {
        background-color: #ced4da;
        padding: 0.5rem;
      }
    }
  }
}

.qr_modal {
  max-width: 25vw !important;

  @media all and (max-width: 480px) {
    max-width: 100vw !important;
  }
}

.modal_question_bank {
  max-width: 66vw !important;
  .modal-content {
    border: none;
  }
  .modal-header {
    .modal-title {
      color: #fff;
    }
  }
  .modal-body {
    .question_bank_card {
      background-color: #eeeeee;
    }
  }
}

.success_modal {
  .modal-content {
    color: #0f5132 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
  }
}
.error_modal {
  .modal-content {
    color: #842029 !important;
    background-color: #f8d7da !important;
    border-color: #f5c2c7 !important;
  }
}
