//
// _SurveyView.scss
//

.surveyView-manual {
  // padding-bottom: 0px;
  .btn-info {
    padding: 7px 15px;
    font-size: 12px;
  }
  .card {
    margin-bottom: 0px;
  }
  // .pagination-nav {
  // 	padding: 0 15px;
  // 	ul {
  // 		margin-bottom: 0.3rem;
  // 	}
  // }
  // .pagination-nav ul.pagination {
  // 	justify-content: end;
  // }
  // .page-item:nth-child(3n + 1) {
  // 	margin: 0 15px;
  // }
  // .page-item.disabled {
  // 	cursor: not-allowed;
  // }
  .survey-view-pagination {
    ul {
      margin: 0.5rem auto;
    }
  }
  .page-link {
    border-color: $primary;
  }
  .page-link:hover {
    background-color: $primary;
    color: #ffffff;
  }
  .dropdown-menu {
    border-top: 2px solid $primary;
    border-radius: 0 2px 4px 4px;
  }
  // .card-header-dropdown {
  //   span i {
  //     &:hover {
  //       color: rgb(0, 0, 0);
  //     }
  //   }
  // }
  // .card-header-dropdown.dropdown.show {
  //   #dropdownMenuReference {
  //     &::after {
  //       content: " ";
  //       position: absolute;
  //       left: 2px;
  //       top: 12px;
  //       border-top: none;
  //       border-right: 7px solid transparent;
  //       border-left: 7px solid transparent;
  //       border-bottom: 7px solid $primary;
  //     }
  //   }
  // }
  .survey-table {
    table-layout: fixed;
  }
  .survey-table thead tr th:first-child {
    width: 20%;
  }
  .survey-table .title-td span:first-child {
    font-weight: bold;
    color: #2fa9e0;
  }
  .survey-table .title-td span:last-child {
    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
    font-weight: 200;
  }
  .survey-table thead tr th:not(:first-child),
  .survey-table tbody tr td:not(:first-child),
  .survey-table tbody tr td .form-check {
    text-align: center;
  }
  .survey-table tbody tr {
    color: rgba(0, 0, 0, 0.5);
  }

  .survey-table tbody tr td {
    span:hover,
    a:hover,
    button:hover {
      color: #182962 !important;
    }
  }
  .share-audience-btn:hover {
    color: #ffffff !important;
  }
}
// .checkbox-form {
// 	border: 0px !important;
// }
.question-row .question-body .checkbox-form .form-check-input[type="checkbox"] {
  width: 1.3em;
  height: 1.3em;
  border-radius: 0px;
}
.upgrade-share:after {
  content: "\eecb";
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 36px;
  color: #f9be00;
}

.predefined-simplebar {
  height: calc(100vh - 230px);
}

@media screen and (max-width: 768px) {
  .predefined-simplebar {
    height: calc(100vh - 216px);
  }
}
