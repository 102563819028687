.survey_new-creation {
  .close-action {
    display: flex;
    color: #6b787f;
    font-weight: bold;
    align-items: center;
    padding: 4px 15px;
    float: left;
    &::before {
      font-family: "remixicon" !important;
      font-style: normal;
      content: "\eb97";
      margin-right: 4px;
      font-size: 14px;
    }
    &:hover {
      background-color: #ddd;
    }
  }
  .sidebar-survey-details {
    height: calc(100vh - 113px);
    padding: 0 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    .survey-name-sidebar {
      color: #333e48;
      line-height: 1.5;
      margin: 0;
      font-weight: 300;
      font-size: 26px;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
      }
      span {
        color: #6b787f;
        font-size: 13px;
        font-weight: 400;
        display: block;
      }
    }
    .add-fav-btn {
      color: #007faa;
      border-color: transparent;
      background-color: transparent;
      display: flex;
      align-items: center;
      float: left;
      clear: both;
      padding: 4px 15px;
      margin-top: 15px;
      &::before {
        font-family: "remixicon" !important;
        font-style: normal;
        content: "\F18B";
        margin-right: 4px;
        font-size: 14px;
      }
      &:hover {
        background-color: #ddd;
      }
    }
    .edit-detail {
      font-size: 13px;
    }
    .survey-inner-detail {
      h2 {
        color: #333e48;
        line-height: 1.5;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }
      h3 {
        font-size: 26px;
        color: #333e48;
        line-height: 1.5;
        margin: 0 0 15px;
        font-weight: 300;
      }
      h4 {
        font-weight: lighter;
        margin: 0 0 15px;
      }
    }
  }
  .copy-survey-btn {
    border-color: #33447c;
    background-color: #33447c;
    padding: 10px 38px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin-left: 5px;
    }
  }
}

.modal-fullscreen {
  max-width: 100vw !important;
}
.warning-modal.modal-sm {
  max-width: 300px;
}

@media only screen and (max-width: 1500px) {
  .modal-fullscreen {
    max-width: 100vw !important;
  }
}
