.share-survey-model {
	max-width: 700px !important;
	.schedule-at-div {
		.flatpickr-wrapper {
			width: 80%;
		}
	}
	.flatpickr-wrapper {
		display: block;
	}

	.share-format-radio {
		border: none;
		width: 0%;
		height: 0%;
		margin-top: 0%;
		&:checked {
			background: transparent;
			border-color: transparent;
		}
	}
	.share-format-radio:checked ~ .card {
		transition: background-color 1s;
		background-color: #405189;
	}
	// .share-format-radio:checked ~ .card {
	// 	h4,
	// 	h5,
	// 	h6,
	// 	p,
	// 	i {
	// 		color: #ffffff !important;
	// 	}
	// }
}
