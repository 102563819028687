.intro-wizard {
  & > .card {
    background-color: transparent;
    box-shadow: none;
  }
  .intro-textarea {
    background-color: rgba(105, 105, 105, 0.9);
    width: 60%;
    height: 60%;
    display: flex;
    border-radius: 0.25rem;
    text-align: center;

    border: none !important;
    color: antiquewhite;

    font-size: larger;
  }
}

.intro-view {
  .intro-textarea {
    background-color: rgba(105, 105, 105, 0.9);
    width: 60%;
    height: 60%;
    display: flex;
    border-radius: 0.25rem;
    text-align: center;
    color: antiquewhite !important;
    font-size: larger !important;
    border: none !important;

    .form-control {
      background-color: transparent;
      border: none;
      margin: auto;
      color: antiquewhite;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 768px) {
    .intro-textarea {
      width: auto;
      height: auto;
      max-height: 85%;
      overflow-y: auto;
      .form-control {
        font-size: 15px;
      }
    }
  }
}
.instruction-view {
  height: 100vh;

  .email-editor {
    color: #010101;
    width: 100%;
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      border: none;
      border-radius: 0.25rem;
      height: 67vh;
      .ql-editor {
        // p{
        //   color: #000 !important;
        //   span{
        //     color: #000 !important;
        //   }
        // }
      }
    }
    /*  STYLE 5 */

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #316fe1;

      // background-image: -webkit-gradient(linear, 0 0, 0 100%,
      //                   color-stop(.5, rgba(255, 255, 255, .2)),
      //           color-stop(.5, transparent), to(transparent));
    }
  }
}

.complete-survey-view {
  .survey-form-layout {
    .survey-form-layout-inner {
      height: 100%;
      .card-header {
        color: rgba(128, 128, 128, 1);
      }
    }

    .scale-radio {
      transition: transform 0.2s;
      .custom_scale_form_check {
        padding-left: 0;
        text-align: center;
        .img-thumbnail {
          width: 50px;
          border: 0px;
          background-color: transparent;
          margin-top: 7px;
        }
      }
      input + label {
        display: inline-flex !important;
      }
      input + label:hover {
        border-radius: 2000px !important;
        background-color: transparent !important;
        transform: scale(1.5);
      }
      input + label:hover span {
        color: #000;
      }
      h4 {
        margin: 0;
        border: 1px solid #cccccc;
        border-radius: 40px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          background-color: transparent !important;
          color: #888;
          border: 0px;
          border-radius: 0px;
          font-size: 12px;
        }
      }
      input:checked + label {
        border-radius: 2000px !important;
        background-color: transparent !important;
        transform: scale(1.5);
      }
      input:checked + label h4 {
        border: 1px solid #cccccc;
      }
      input:checked + label span {
        color: rgb(0, 0, 0);
      }
    }
    & > .card-header {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }

  .survey-back-next-buttons {
    position: fixed;

    bottom: 10%;
    left: 20.5%;
    // button {
    //   position: fixed;
    //   bottom: 10%;
    // }

    .back-button {
      background-color: rgba(128, 128, 128, 0.25);
      border-color: transparent;
      color: rgba(128, 128, 128, 1);
    }
    // .next-button {
    //   left: 27%;
    // }
  }
  //   .single-survey-back-next-buttons {
  //     .next-button {
  //       left: 27%;
  //     }
  //   }

  @media screen and (min-width: 769px) and (orientation: portrait) {
    .md-height-100 {
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .mobile-btn {
      border: 0px;
      padding: 0;
      background-color: #e0e2e3;
      position: relative;
      border-radius: 0.25rem;
    }
    .survey-form-layout-inner {
      height: 93% !important;
    }
  }
}
