$phone: 640px;
$tablet: 768px;
$desktop: 1024px;
$desktop1: 1600px;

.survey-analysis {
  box-shadow: none;
  .card {
    box-shadow: none;
    margin-bottom: 0.5rem;
  }
  .nav-link {
    color: $gray-700;
    &.active {
      color: #fff !important;
      background-color: $primary !important;

      &::after {
        display: none;
        background-color: $primary !important;
      }
    }
  }
  .action-btns {
    button {
      border-right: 2px solid #fff;
      &:last-child {
        border-right: 0px;
      }
    }
  }
  .analysis-question-title {
    font-weight: 300;
    width: 95%;
  }
  // .individual-survey-response {
  // 	box-shadow: none;
  // }

  .statsbox {
    border: 1px solid #efefef;
    padding: 16px;
    .statsbox_svg {
      width: 60px;
      height: 60px;
      background-color: $polynesian-blue;
    }
    .stats-left-section {
      display: inline-block;
      width: 50%;
      text-align: center;
      border-right: 1px solid #efefef;
    }
    .stats-right-section {
      display: inline-block;
      width: 50%;
      text-align: center;
    }
    .label {
      display: block;
      color: #7c878e;
      font-size: 0.8125em;
      font-weight: 700;
      line-height: 1.3em;
    }
    .value {
      color: #00529e;
      display: block;
      font-size: 1em;
      font-weight: 700;
      line-height: 1.5em;
    }
    i {
      color: #d0d2d3;
      font-size: 17px;
      cursor: help;
      &:hover {
        color: #182962;
      }
    }
  }
  .stats_accordion {
    .accordion-item {
      .accordion-header {
        font-size: 0.7rem;

        .accordion-button {
          font-size: inherit;
          padding: 0.5rem;
        }

        // @media screen and (max-width: $phone) {
        //   font-size: large;
        // }
        @media screen and (min-width: $desktop1) {
          font-size: 0.7rem;
        }
      }
      .accordion-body {
        padding: 0.5rem;
      }
    }
  }
}

.custom-sent-dash-link {
  text-decoration: underline !important;
  text-underline-offset: 3px !important;
  &:hover {
    background-color: $primary;
    color: #fff;
    text-decoration: none !important;
    border-radius: 3px;
    padding: 0.5px;
  }
}
