$gold: #b3daff;
$coral: #1a91ff;
$brightCoral: #00529e;

#page {
  $opacity: 90%;
  align-items: center;
  background: linear-gradient(
    to top right,
    mix($coral, white, $opacity) 10%,
    mix($brightCoral, white, $opacity) 65%,
    mix($gold, white, $opacity) 125%
  );
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;

  right: 0;
  top: 0;
  transition: opacity 1s;
  position: relative;
  width: 100%;
  height: 100%;
}

#phrase_box {
  overflow: hidden;
  width: 260px;
  position: absolute;
  height: 150px;
}

#phrases {
  animation: slide-phrases-upward 20s infinite;
}

#footer {
  bottom: 30px;
  color: white;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
}

#phrase-container {
  position: relative;
  font-size: 24px;
}

.phrase {
  color: antiquewhite;
  position: absolute;
  // top: -15%;
  opacity: 0;
  transition: top 3s ease-in-out, bottom 3s ease-in-out, opacity 3s ease-in-out;
}

.phrase.active {
  top: 45%;
  opacity: 1;
}
.phrase.prev {
  top: -15%;
  opacity: 0;
}
.phrase.next {
  top: 110%;
  opacity: 0;
}

@keyframes slide-phrases-upward {
  @for $i from 0 through 20 {
    #{$i * 5%} {
      transform: translateY($i * -50px);
    }
  }
}

// @for $i from 0 through 20 {
//   #loadingCheckCircleSVG-#{$i} {
//     animation: fill-to-white 5000ms;
//     animation-delay: ($i - 1.5) * 1s;
//     fill: white;
//     opacity: 0;
//   }
// }
@keyframes fill-to-white {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
// @for $i from 0 through 20 {
//   #loadingCheckSVG-#{$i} {
//     animation: fill-to-coral 5000ms;
//     animation-delay: ($i - 1.5) * 1s;
//   }
// }
@keyframes fill-to-coral {
  0% {
    fill: white;
  }
  10% {
    fill: $brightCoral;
  }
  100% {
    fill: $brightCoral;
  }
}

// /* Optional: Highlight the active phrase */
// .phrase.active {
//   font-weight: bold;
// }
