.survey-general {
	.card {
		box-shadow: none;
		.card-body {
			i {
				position: relative;
				top: -41px;
				background-color: #ffffff;
				color: rgba($primary, 1);
				width: 42px;
				padding: 0;
				margin: 0;
				height: 42px;
				box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
				-webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
			}
		}
	}
	.survey-format-card {
		.survey-format-radio {
			border: none;
			width: 0%;
			height: 0%;
			margin-top: 0%;
			&:checked {
				background: transparent;
				border-color: transparent;
			}
		}
		.survey-format-radio:checked ~ .card-body {
			transition: background-color 1s;
			background-color: rgba($primary, 1);
		}
		.survey-format-radio:checked ~ .card-body {
			h5,
			h6,
			p {
				color: #fff !important;
			}
		}
	}
}
