.information-main {
	.information-tab-card {
		.info-search-col {
			p {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
		.team-box {
			&:hover {
				background-color: #f5f7fa;
				cursor: pointer;
			}
		}
	}
}

.search-details {
	overflow: hidden;
	position: relative;
	width: 100%;
	min-height: 100vh;

	.demo-bg {
		opacity: 0.5;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
	}
	.search-details-inner {
		position: relative;
	}
	.modal-content {
		background-color: transparent;
	}
}
