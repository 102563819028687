.echarts-for-react {
  height: 50vh !important;
  width: 100%;

  div:first-child {
    width: 100% !important;
    height: 100% !important;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
#zingchart-react-0 {
  #zingchart-react-0-wrapper {
    width: 100%;
    height: 100%;
    .zc-svg {
      width: 100%;
      height: 100%;
    }
    .zc-svg {
      width: 100%;
      height: 100%;
    }
    // #zingchart-react-0-top {
    //   .zc-map,
    //   .zingchart-react-0-license-text {
    //     display: none !important;
    //   }
    // }
  }
}
.chart-menu-box {
  display: flex;
  align-items: center;
  .btn-group-vertical {
    // position: absolute;
    // right: -15px;
    display: flex;
    align-items: end;
    width: 100%;
    button {
      border-radius: 0px;
      border-left: 0px;
      border-right: 0px;
      width: 100px;
      &:hover {
        width: 130px;
        transition: width 0.5s;
      }
      &.active {
        width: 130px;
        transition: width 0.5s;
      }
    }
  }
}
.echarts-for-react1 {
  height: 30vh !important;
}
.apexcharts-theme-light {
  bottom: -38px;
}
