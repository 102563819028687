// 
// _popover.scss
// 

.popover {
    box-shadow: $popover-box-shadow;
    .popover-header{
        display: flex;
        align-items: center;
        // justify-content: center;
        font-weight: bold;
        border-bottom: 0px;
    }
    .upgrade-lock{
        background-color: #F9BE00;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        margin-right:15px
    }
    
}