.lock_button {
	padding: 0.25rem 0.5rem !important;
	background-color: transparent;
	border: 1px solid #f9be00;
	display: flex;
	align-items: center;
	justify-content: center;
}
.add-btn-lock{
	border: 0px;
}
.add-btn-lock:after{
	content: "\eecb";
    font-family: 'remixicon' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
	display: none;
}
.add-btn-lock:hover:after{
    display: flex;
	justify-content: center; align-items: center;
	background-color: rgba(249, 190, 0, 1);;
	color: #000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 13px;

}