.App {
  text-align: center;
}

.treeview > .toggle {
  pointer-events: all;
  cursor: pointer;
}
.treeview > .toggle:hover {
  color: #63b1de;
  fill: #63b1de;
}

.selected {
  border-bottom: 3px solid #11bb9a !important;
  color: #11bb9a !important;
}

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}
