.question-row {
  .question-body {
    overflow-x: hidden;
    .dropZone-addQuestion {
      width: 80%;
      margin-bottom: 0%;
      height: 0%;
      border-style: dashed;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      .dropzone_message_div {
        height: 0%;
        width: auto;
        border: none;
      }
    }
    .add-question-button {
      position: relative;
      z-index: 33;
    }
    .question_type_dropdown.show {
      display: flex;
    }
    .question_type_dropdown {
    }
    .dropdown-item {
      width: 50%;
      margin-top: 5px;
      i {
        font-size: 17px;
      }
    }

    // .question-action-buttons,
    // .question-add-button,
    // .question-ribbon {
    // 	display: none;
    // }

    // .place-question-type-area:hover + .add-question-button {
    // 	display: flex;
    // }
    // .place-question-type-area:hover + .ribbon-manual {
    // 	display: inline-block;
    // }
    .question-body-inner {
      position: relative;

      // .question-action-buttons {
      //   visibility: hidden;
      //   position: relative;
      //   .survey-action-btns {
      //     width: 70px;
      //     padding: 4px 12px;
      //     // width: 29px;
      //     // height: 29px;
      //     border-radius: 0px;
      //     i {
      //       font-size: 14px;
      //     }
      //   }
      //   // #editIcon{
      //   // 	background-color: $success;
      //   // 	border-color: $success;
      //   // }
      //   #deleteIcon,
      //   #moveIcon,
      //   #cloneIcon {
      //     background-color: $gray-600;
      //     border-color: $gray-600;
      //   }
      // }
      // .place-question-type-area {
      .card {
        background-color: transparent;
      }
      .other-input-field {
        background-color: $input-disabled-bg;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .other-input-field.form-switch {
        padding-left: 0rem;
        .form-check-label {
          background-color: inherit;
        }
        .form-check-input {
          position: absolute;
          right: 7px;
          width: 3em;
          height: 1.7em;
          top: 5px;
        }
      }
      // }
      // &:hover {
      //   background-color: $gray-200;
      //   .question-action-buttons {
      //     visibility: visible;
      //   }
      // }
      .dropZone {
        background-color: transparent; /* rgb(232, 232, 232) */
        padding: 0 0; /* 0.5rem 0rem */
        border-radius: 0px; /* 0.3rem */
      }
      .nps-input-row {
        .nps-input-col {
          &:first-child {
            padding-right: 0px;
            input {
              border-right: 0px;
              border-radius: 0px;
            }
          }
          &:last-child {
            padding-left: 0px;
            input {
              border-left: 0px;
              border-radius: 0px;
            }
          }
        }
        .to-section {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border: 1px solid #ced4da;
        }
      }
    }
    // .borderColor {
    // 	// border-color: #cccccc !important;
    // }
    .checkbox-form {
      .form-check-input[type="checkbox"] {
        width: 1.3em;
        height: 1.3em;
        border-radius: 0px;
        // border-color: #cccccc !important;
      }
      .check-label {
        display: inherit;
        padding: 2px 10px;
        font-size: 12px;
      }
      // .form-check-input:checked {
      //   border-color: #405189 !important;
      // }
      .form-check-input:not(:checked):hover {
        background-color: #f7f6f6 !important;
      }
      .form-check-input[type="radio"] {
        width: 1.3em;
        height: 1.3em;
        // border-color: #cccccc !important;
        // &:checked {
        // 	// border-color: $blue !important;
        // }
      }
    }
    .scale-radio {
      transition: transform 0.2s;
      .custom_scale_form_check {
        padding-left: 0;
        text-align: center;
        .img-thumbnail {
          width: 50px;
          border: 0px;
          background-color: transparent;
          margin-top: 7px;
        }
      }
      input + label {
        display: inline-flex !important;
      }
      input + label:hover {
        border-radius: 2000px !important;
        background-color: transparent !important;
        transform: scale(1.5);
      }
      input + label:hover span {
        color: #000;
      }
      h4 {
        margin: 0;
        border: 1px solid #cccccc;
        border-radius: 40px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          background-color: transparent !important;
          color: #888;
          border: 0px;
          border-radius: 0px;
          font-size: 12px;
        }
      }
      input:checked + label {
        border-radius: 2000px !important;
        background-color: transparent !important;
        transform: scale(1.5);
      }
      input:checked + label h4 {
        border: 1px solid #cccccc;
      }
      input:checked + label span {
        color: rgb(0, 0, 0);
      }
    }
    .rating-scale-view {
      display: none;
      span {
        flex-grow: 1 !important;
        text-align: center;
      }
    }
    .dropdown-select-widget {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      i {
        font-size: 17px;
      }
    }

    .options_table {
      width: 100%;
      transition: width 2s;
      th {
        font-weight: 500 !important;
        padding: 0.3rem 0.6rem;
        width: 25%;
      }
      td {
        padding: 0.3rem 0.6rem;
        vertical-align: middle;
      }
    }
  }
  .mention-input-styling {
    .mention-input-styling__control {
      min-height: auto !important;
    }
  }
  .member-active-switch {
    width: 57px;
    .form-check-input {
      left: inherit;
      right: -39px;
    }
  }
  .question-body-edit-bg {
    // background-color: $gray-200;
  }
  .ribbon-box {
    position: relative;

    .ribbon-manual {
      padding: 5px 12px;
      box-shadow: 2px 5px 10px rgba($dark, 0.15);
      color: $white;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      position: absolute;
      left: -1px;
      top: 5px;
      // display: none;
    }
  }

  .question-type-card {
    // position: fixed;
    // min-width: 16%;
    height: calc(100vh - 91px);
    border: 1px solid #d3d2d2;
    .card-header {
      background-color: #ffffff; //$gray-500
      border-top: 0px solid #d3d2d2;
      border-bottom: 1px solid #d3d2d2;
      color: #000000;
      font-weight: 700;
    }
    .sideBarItem {
      margin: 0; //0.5rem 0.2rem
      padding: 0.5rem;
      background-color: #ffffff; //$gray-100
      text-align: center;
      // box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
      // -webkit-box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
      // -moz-box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
      // border-top:1px solid #d3d2d2;
      // border-bottom: 1px solid #d3d2d2;
      i {
        font-size: 17px;
      }
      .add-type-btn {
        border: 0px;
        background: #e5e5eb;
        padding: 4px 15px;
        visibility: hidden;
      }
    }
    .sideBarItem:hover {
      cursor: move;
      background-color: $primary;
      // color: #ffffff !important;
      color: #405189;
      .add-type-btn {
        visibility: visible;
      }
      i.bxs-edit {
        &::before {
          content: "" !important;
          border: 1px solid #fff;
          width: 13px;
          height: 5px;
          display: inherit;
        }
      }
    }
  }
  .question-card {
    position: relative;

    .dropzone-change-question-position {
      border-style: dashed;
    }
    & > .card-header {
      position: sticky;
      z-index: 3;
      box-sizing: border-box;
      background-color: #e5e5eb; // $gray-500
      color: antiquewhite;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: inherit;
      width: inherit;
      .card-title {
        margin: 0;
        color: antiquewhite;
        font-weight: 700;
      }
      button {
        margin: 0rem 0.7rem;
      }
    }
    .card-body {
      .card {
        box-shadow: 0px 0px 1px 0px rgba(135, 135, 135, 0.75);
        -webkit-box-shadow: 0px 0px 1px 0px rgba(135, 135, 135, 0.75);
        -moz-box-shadow: 0px 0px 1px 0px rgba(135, 135, 135, 0.75);
      }
      .box-equel-height {
        border: 1px solid #ced4da;
        border-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .question-add-btn {
        background-color: transparent;
        border-radius: 0px;
        border-right: 0px;
        border-color: transparent;
        color: #000;
        font-weight: bold;
        white-space: nowrap;
      }
      .question-select-btn-div {
        border: 1px solid #ced4da;
        border-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .question-select-btn {
        background-color: transparent;
        border-radius: 0px;
        border-left: 0px;
        border-color: transparent;
        color: #000;
        font-weight: bold;
        height: 44px;
        white-space: nowrap;
      }
    }
    .btn-secondary {
      background-color: #5c636a;
      border-color: #5c636a;
    }
  }

  .question-input-edit {
    height: 46px;
  }
  .edit-input-container {
    margin-top: 15px;
  }
  .edit-input-container:last-child {
    margin-bottom: 15px;
  }
}
.scale-check-form {
  input {
    margin-right: 10px;
  }
  .select-emo {
    img {
      width: 40px;
      margin-right: 10px;
    }
  }
}
.questions-body {
  overflow-x: hidden;
  .add-section-row {
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 2;
    button {
      border: none;
    }
    p,
    button {
      color: #2fa9e0;
    }
    border-bottom: 1px dashed #b3abab;
    border-top: 1px dashed #b3abab;
    .add-section-edit {
      visibility: hidden;
    }
    p {
      color: $primary;
    }
    &:hover {
      background-color: #eff2f7;
      .add-section-edit {
        visibility: visible;
      }
    }
    &:first-child {
      border-top: 0px dashed #b3abab;
    }
  }
}
.pages-section-container {
  overflow-x: hidden;
  .add-section-row {
    border-bottom: 1px dashed #b3abab;
    border-top: 1px dashed #b3abab;
    .add-section-edit {
      visibility: hidden;
    }
    p {
      color: $primary;
    }
    &:hover {
      background-color: #eff2f7;
      .add-section-edit {
        visibility: visible;
      }
    }
  }
  &:first-child {
    .add-section-row {
      border-top: 0px dashed #b3abab;
    }
  }
}
.add-section-end {
  border-top: 1px dashed #b3abab;
  .page-end-section {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    display: block;
    width: 100%;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
i.bxs-edit {
  &::before {
    content: "" !important;
    border: 1px solid #000;
    width: 13px;
    height: 5px;
    display: inherit;
  }
}
.slider {
  justify-content: center;
  align-items: center;
  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: block;
    box-shadow: none;
  }
  .rangeslider {
    .rangeslider__labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: inherit;
      margin-top: 9px;
      .rangeslider__label-item {
        position: unset;
        font-size: 14px;
        cursor: pointer;
        display: inline-block;
        transform: unset;
        &:first-child {
          margin-left: 0.6rem;
        }
      }
    }
    .rangeslider__fill {
      box-shadow: none !important;
    }
    .rangeslider__handle {
      background: $primary;
      box-shadow: none;
    }
  }

  .rangeslider-horizontal {
    height: 1vh;
    .rangeslider__handle {
      width: 21px;
      height: 21px;
      &:after {
        content: " ";
        position: absolute;
        width: 11px;
        height: 11px;
        top: 4.5px;
        left: 4.5px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: none;
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
        //   0 -1px 3px rgba(0, 0, 0, 0.4) inset;
      }
    }
  }
}
.skipping-modal {
  .table thead tr th:first-child {
    width: 10%;
  }
  .table thead tr th:nth-child(2) {
    width: 20%;
  }
  .table thead tr th:nth-child(3) {
    width: 25%;
  }
  .table thead tr th {
    width: -webkit-fill-available;
  }

  .table tbody tr td:nth-child(2) {
    word-break: break-word;
  }

  // table tbody tr td {
  // 	width: 25%;
  // }
}

.single_question_card {
  box-shadow: none;
  margin-bottom: 0;
  border: none;
  box-shadow: none !important;

  .add-choice-element {
    color: rgba(128, 128, 128, 1);
    text-underline-offset: 0.2rem;
  }

  .card-header {
    label {
      color: rgba(128, 128, 128, 1);
    }
    & > div {
      color: rgba(135, 135, 135, 0.7);
      font-size: 1rem;
    }
    div {
      &:last-of-type {
        color: rgba(135, 135, 135, 0.5);
        font-size: 0.75rem;
        font-weight: normal;
      }
    }
  }

  .checkbox-form {
    background-color: rgba(234, 245, 255, 0.7);
    border: 1px solid rgba(128, 128, 128, 0.3);
    border-radius: 0% !important;
    &:hover {
      background-color: rgb(226, 226, 226);
    }
  }
  .checkbox-form-matrix {
    // background-color: rgba(234, 245, 255, 0.7);
    // border: 1px solid rgba(128, 128, 128, 0.3);
    border-radius: 0% !important;
    &:hover {
      background-color: rgb(226, 226, 226);
    }
  }

  & > .form-label {
    font-size: 18px;
    // color: #000000;
    color: rgba(128, 128, 128, 1);
    font-weight: 600;
    font-style: italic;
    // margin-top: 30px;
    p {
      font-size: 12px;
      // color: #000000;
      color: rgba(128, 128, 128, 0.5);

      font-style: italic;
      // margin-top: 30px;
    }
  }

  .card-body label {
    font-weight: 300;
  }

  .matrix-row-label,
  .matrix-column-label {
    display: flex;
    justify-content: center;
    align-items: end;
    text-align: center;
  }
}

.custom_scale_form_check i {
  font-size: 4em !important;
}

.custom_card_simplebar {
  height: calc(100vh - 255px);
}

.under_dev_simplebar {
  margin-bottom: 2rem;
}

.scale-icon-danger {
  color: red;
}
.scale-icon-warning {
  color: yellow;
}
.scale-icon-success {
  color: green;
}

#scale_radios {
  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .form-check label {
    /* background-color: blue; */
    margin: 0.1rem;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0em;
  }
}

// @media screen and (max-width: 768px) {
//   .custom_card_simplebar {
//     height: calc(100vh - 183px);
//   }
// }

// #file_img_questionnaire {
//   display: none;
// }

// #file_label_img_questionnaire {
//   font-size: 1rem;
//   padding: 0.375rem 0.75rem;
//   border: 1px solid $primary;
//   border-radius: 0.25rem;
//   cursor: pointer;
//   border: 1px solid $primary;
//   color: $primary;
// }

// .input_image_questionnaire::file-selector-button {
//   border: 2px solid #6c5ce7;
//   padding: 0.2em 0.4em;
//   border-radius: 0.2em;
//   background-color: #a29bfe;
//   transition: 1s;
// }

// .input_image_questionnaire::-ms-browse:hover {
//   background-color: #81ecec;
//   border: 2px solid #00cec9;
// }

// .input_image_questionnaire::-webkit-file-upload-button:hover {
//   background-color: #81ecec;
//   border: 2px solid #00cec9;
// }
