.complete-survey-view {
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  .simplebar-track.simplebar-horizontal {
    display: none;
  }
  .custom-tabcontent-survey {
    position: relative;
    width: 100%;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
      box-shadow: none !important;
    }
  }

  .custom-nav-survey {
    overflow-x: hidden;
    width: 100%;
    z-index: 1;
    position: fixed;
    background-color: #f3f3f9;
  }
  .complete-survey-view-modal {
    .modal-title {
      color: azure;
    }
  }
  .back-btn {
    border: 3px solid #000;
    border-left: 0px;
    width: 37px;
    height: 66px;
    border-radius: 0 88px 88px 0px;
    text-align: left;
    padding: 0px 0px 0px 5px;
    transition: width 0.5s, height 0.5s;
  }
  .back-btn:hover {
    background-color: #405189 !important;
    color: #fff !important;
    border-color: #33447c !important;
    width: 42px;
    height: 69px;
  }
  .back-btn:hover svg {
    display: none !important;
  }
  .back-btn:hover::before {
    content: "Back";
    display: block;
    font-size: 11px;
    font-weight: bold;
  }
  .next-btn {
    border: 3px solid #000;
    border-right: 0px;
    width: 37px;
    height: 66px;
    border-radius: 88px 0px 0px 88px;
    text-align: right;
    padding: 0px 5px 0px 0;
    transition: width 0.5s, height 0.5s;
  }
  .next-btn:hover {
    background-color: #405189 !important;
    color: #fff !important;
    border-color: #33447c !important;
    width: 42px;
    height: 69px;
  }
  .next-btn:hover svg {
    display: none !important;
  }
  .next-btn:hover::before {
    content: "Next";
    display: block;
    font-size: 11px;
    font-weight: bold;
  }
  .submit-btn {
    border: 3px solid #000;
    border-right: 0px;
    width: 37px;
    height: 66px;
    border-radius: 88px 0px 0px 88px;
    text-align: right;
    padding: 0px 5px 0px 0;
    transition: width 0.7s, height 0.7s;
  }
  .submit-btn:hover {
    background-color: #405189 !important;
    color: #fff !important;
    border-color: #33447c !important;
    width: 60px;
    height: 110px;
  }
  .submit-btn:hover svg {
    display: none !important;
  }
  .submit-btn:hover::before {
    content: "Submit";
    display: block;
    font-size: 11px;
    font-weight: bold;
  }
  .form-check-input[type="radio"] {
    width: 1.3em;
    height: 1.3em;
    // border-color: #cccccc !important;
    &:checked {
      background-color: #405189;
      border-color: #405189;
    }
  }
  .form-check-input[type="checkbox"] {
    width: 1.3em;
    height: 1.3em;
    border-radius: 0px;
    border-color: #cccccc !important;
    &:checked {
      border-color: #405189 !important;
    }
  }
  .rating-scale-view {
    display: none;
    span {
      flex-grow: 1 !important;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      & > span {
        width: 25%;
      }
    }
  }
  .scale-radio {
    transition: transform 0.2s;
    .custom_scale_form_check {
      padding-left: 0;
      text-align: center;
    }
    input + label {
      display: inline-flex !important;
    }
    input + label:hover {
      border-radius: 2000px !important;
      background-color: transparent !important;
      transform: scale(1.5);
    }
    input + label:hover span {
      color: #000;
    }
    h4 {
      margin: 0;
      border: 1px solid #cccccc;
      border-radius: 40px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        background-color: transparent !important;
        color: #888;
        border: 0px;
        border-radius: 0px;
        font-size: 12px;
      }
    }
    input:checked + label {
      border-radius: 2000px !important;
      background-color: transparent !important;
      transform: scale(1.5);
    }
    input:checked + label h4 {
      border: 1px solid #cccccc;
    }
    input:checked + label span {
      color: #000;
    }
  }
  .card-header {
    background-color: #e5e5eb;
    color: #000;
  }
}

.predefined-question-input-group:hover {
  z-index: 10;
}
