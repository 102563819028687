//
// _dashboard.scss
//

.dash-filter-picker {
  min-width: 210px !important;
}

// Flatpickr Calendar

.upcoming-scheduled {
  position: relative;
  .flatpickr-months {
    position: absolute !important;
    top: -45px !important;
    left: auto !important;
    right: 0px !important;
    width: 200px;
    background-color: transparent;

    .flatpickr-month {
      color: $gray-600 !important;
      fill: $gray-600 !important;
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      display: none;
    }
  }
  .flatpickr-calendar {
    box-shadow: none !important;
    .flatpickr-current-month {
      font-size: 13px;
      width: 100%;
      left: 0;
    }
    .flatpickr-monthDropdown-months {
      border: 1px solid $border-color;
      border-radius: 4px;
      height: 26px;
    }
    .flatpickr-weekdays {
      background-color: var(--#{$variable-prefix}light);
      border: none;
      span.flatpickr-weekday {
        color: var(--#{$variable-prefix}dark);
      }
    }
    .flatpickr-day {
      &.today {
        color: $white !important;
        background-color: $success;
        border-color: $success !important;
        &:hover {
          color: $success !important;
          background-color: rgba($success, 0.2) !important;
        }
      }
      &.selected {
        background-color: $success !important;
        border-color: $success !important;
        color: $white;
      }
    }
    .numInputWrapper {
      width: 7.5ch;
      margin-left: 10px;
    }
  }

  .flatpickr-days {
    border: none !important;
  }
}

.crm-widget {
  .col {
    border-right: 1px solid $border-color;
    &:last-child {
      border: 0px;
    }
    @media (min-width: 768px) and (max-width: 1399.98px) {
      &:nth-child(3) {
        border-right: 0px;
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
    @media (max-width: 767.98px) {
      border-right: 0px;
      border-bottom: 1px solid $border-color;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .project-wrapper {
    > .col-xxl-8,
    .col-xxl-4 {
      width: 100%;
    }
  }
}

.crypto-widget {
  max-width: 130px !important;
}

// Antlere Smart CSS
body {
  background-color: #f6f6f6;
}
.welcom-heading {
  font-size: 1.8em;
  padding: 0px;
  font-weight: 300;
  a {
    color: #007faa;
  }
}
.p-complete-link {
  color: #007faa;
  font-size: 1em;
  font-weight: 400;
}

.statsbox {
  padding: 16px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  .statsbox_svg {
    width: 60px;
    height: 60px;
    // background-color: $polynesian-blue;
  }
  .stats-left-section {
    display: inline-block;
    width: 50%;
    text-align: center;
    border-right: 1px solid #efefef;
  }
  .stats-right-section {
    display: inline-block;
    width: 50%;
    text-align: center;
  }
  .label {
    display: block;
    color: #f6f6f6;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.3em;
  }
  .value {
    color: #f6f6f6;
    display: block;
    font-size: 1.7em;
    font-weight: 300;
    line-height: 1.5em;
  }
  i {
    color: #d0d2d3;
    font-size: 17px;
    cursor: help;
    &:hover {
      color: #182962;
    }
  }
}
.button-upgrade {
  border-color: #f9be00;
  color: #333e48;
  background-color: #f9be00;
  font-weight: 500;
  padding: 8px 14px;
  float: right;
  clear: both;
  margin-top: 20px;
}

.section-heading {
  font-weight: lighter;
  font-size: 1.7em;
  color: #333e48;
  margin-bottom: 30px;
}
.section-content {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #7c878e;
}
.section-header {
  border-bottom: 1px solid #edeeee;
  padding: 20px;
  font-size: 12px;
  color: #7c878e;
  .section-header-heading {
    color: #333e48;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .section-header-content {
    font-size: 11px;
    font-style: italic;
    color: #7c878e;
    margin-bottom: 0px;
  }
}
.insight-container {
  padding: 20px 40px;
  .learn-heading {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #333e48;
  }
  .insign-listing {
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
  }
  .insign-listing li {
    font-size: 13px;
    font-weight: 400;
    color: #7c878e;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .insign-listing li:before {
    content: "\eb7b";
    font-family: "remixicon" !important;
    font-style: normal;
    font-size: 17px;
    margin-right: 13px;
  }
  .insight-content {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 400;
    color: #7c878e;
  }
  .insight-img {
    max-width: 100%;
    height: auto;
  }
}
.profile-container {
  h4 {
    a {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: #333e48;
    }
  }
  .user-info-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333e48;
    a {
      color: #007faa;
    }
    small {
      margin: 4px 76px 0px;
      text-align: left;
      font-size: 0.9em;
      color: #7c878e;
    }
  }
  .separator {
    margin: 10px 0px;
    border-top: 1px solid #efefef;
  }
  .upgrade-btn {
    a {
      display: inline-flex;
      align-items: center;
      padding: 0 0.5rem;
      i {
        font-size: 28px;
      }
    }
  }
}
.poll-container {
  img {
    width: 100%;
    height: 175px;
  }
  .headline {
    line-height: 19px;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 15px;
  }
  .description {
    line-height: 19px;
    font-size: 13px;
    height: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .excerpt {
    height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
.ribbon-three-recent {
  top: 0;
  &::after {
    border: 0;
  }
  span {
    border-radius: 0 0 4px 4px;
    padding: 5px 5px 5px;
    width: auto;
    &::before {
      background-color: transparent;
    }
    &::after {
      background-color: transparent;
    }
  }
}
.tooltip-test {
  display: flex;
}

// .show {
// 	opacity: 1 !important;
// }

.ribbon-three-recent {
  top: 0;
  &::after {
    border: 0;
  }
  span {
    border-radius: 0 0 4px 4px;
    padding: 5px 10px 5px;
    width: auto;
    &::before {
      background-color: transparent;
    }
    &::after {
      background-color: transparent;
    }
  }
}
.hover-card {
  cursor: default;
}
.hover-card:hover {
  border: 1px solid #405189;
}

.recent_survey_main {
  .team-box {
    .team-content {
      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .card-header-dropdown {
      .dropdown-item {
        &:hover {
          color: #0ab39c;
          transition: transform 0.2s;
          a:not(.upgrade_a),
          div {
            color: $polynesian-blue-500 !important;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .btn-group {
    .btn-check:checked + .btn-light,
    .btn-check:active + .btn-light,
    .btn-light:active,
    .btn-light.active,
    .show > .btn-light.dropdown-toggle {
      color: #efefef;
      background-color: #979797;
      border-color: #979797;
    }
  }
}
