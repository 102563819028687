//
// _landing.scss
//

.layout-wrapper{
    &.landing{
        background-color: $card-bg;
    }
}

