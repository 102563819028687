.stats_loader {
  position: relative;
  width: 75px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;

  &::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: #00529e;
    border-radius: 50%;
    animation: ballStepUp 4s linear infinite;
  }
}
@keyframes pillerPushUp {
  0%,
  40%,
  100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%,
  90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}

@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.survey_loader {
  position: relative;
  width: 100px;
  height: 130px;
  background: #00284d;
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    width: 54px;
    height: 25px;
    left: 50%;
    top: 0;
    background-image: radial-gradient(
        ellipse at center,
        #0000 24%,
        #00529e 25%,
        #00529e 64%,
        #0000 65%
      ),
      linear-gradient(to bottom, #0000 34%, #00529e 35%);
    background-size: 12px 12px, 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    transform: translate(-50%, -65%);
    box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
  }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 66%;
    height: 60%;
    background: linear-gradient(to bottom, #00529e 30%, #0000 31%);
    background-size: 100% 16px;
    animation: writeDown 2s ease-out infinite;
  }
}
@keyframes writeDown {
  0% {
    height: 0%;
    opacity: 0;
  }
  50% {
    height: 50%;
    opacity: 1;
  }
  // 20% {
  //   height: 0%;
  //   opacity: 1;
  // }
  // 80% {
  //   height: 65%;
  //   opacity: 1;
  // }
  100% {
    height: 65%;
    opacity: 0;
  }
}

.success_div {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 10000000;
  top: 0;
  .spinner-border {
    width: 7rem;
    height: 7rem;
  }
}
.div_loader_trans {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: auto;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);

  .spinner-border {
    width: 7rem;
    height: 7rem;
  }
}
.div_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .spinner-border {
    width: 7rem;
    height: 7rem;
  }
}

.overlay_upgrade_div {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.5); */
  position: absolute !important;
  z-index: 10;
  top: 0;
  right: 13px;
  /* padding: 13rem; */
}
.loading {
  display: flex;
}
.loading .dot {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0.8em;
  border-radius: 50%;
}
.loading .dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: wave 2s ease-out infinite;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.loading .dot:nth-child(1) {
  background: #7ef9ff;
}
.loading .dot:nth-child(1)::before {
  animation-delay: 0.2s;
}
.loading .dot:nth-child(2) {
  background: #89cff0;
}
.loading .dot:nth-child(2)::before {
  animation-delay: 0.4s;
}
.loading .dot:nth-child(3) {
  background: #4682b4;
}
.loading .dot:nth-child(3)::before {
  animation-delay: 0.6s;
}
.loading .dot:nth-child(4) {
  background: #0f52ba;
}
.loading .dot:nth-child(4)::before {
  animation-delay: 0.8s;
}
.loading .dot:nth-child(5) {
  background: #000080;
}
.loading .dot:nth-child(5)::before {
  animation-delay: 1s;
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }
  80%,
  100% {
    opacity: 0;
  }
}
