.public_survey_view {
	.bg-transparent-div {
		height: 100%;
	}
	.simple_bar-public-list {
		.simplebar-content-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.survey-card-link {
			&:hover {
				box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.75);
				-webkit-box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.75);
			}
		}
	}
	.intro-textarea-top {
		background-color: rgba(255, 255, 255, 0.9);

		border-radius: 0.25rem;
		padding: 20px 0;
		margin: 0 auto;

		.form-control {
			background-color: transparent;
			border: none;
			margin: auto;
			color: rgb(0, 0, 0);
			font-size: x-large;
		}
	}
	.intro-textarea {
		text-align: center;

		background-color: rgba(255, 250, 250, 0.9);
		// width: 60%;
		height: 100%;
		// display: flex;
		border-radius: 0.25rem;
		padding: 20px 0;
		margin: 0 auto;

		.form-control {
			background-color: transparent;
			border: none;
			margin: auto;
			color: rgb(20, 20, 20);
			font-size: large;
		}
	}
	.survey-selection {
		color: #000;
		.card-title {
			color: #000;
			height: 36px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* number of lines to show */
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.card-text {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3; /* number of lines to show */
			line-clamp: 3;
			-webkit-box-orient: vertical;
			height: 60px;
		}
	}

	@media screen and (max-width: 768px) {
		height: auto !important;

		.bg-transparent-div {
			max-height: 47vh;
			.avatar-sm {
				max-width: 40% !important;
			}
			.intro-textarea {
				.form-control {
					max-height: 176px;
					overflow-y: auto;
				}
			}
		}
		.survey_listings {
			margin-top: 1rem;
		}

		.simple_bar-public-list {
			.simplebar-content-wrapper {
				display: block;
			}
		}
	}
}
