.survey-content {
  .create-survey-container {
    height: calc(100% - 67px);
    position: relative;
    top: 70px;
    left: 0;
    right: 0;

    .row {
      &:first-child {
        // min-height: calc(100vh - 70px);
      }
      .question-info-col-1 {
        color: rgba(78, 78, 78, 1);
        .breadcrumb {
          margin-bottom: 0%;
          .breadcrumb-item {
            color: rgba(78, 78, 78, 0.4);
          }
          .active {
            color: rgba(78, 78, 78, 1);
          }
        }
      }
    }

    .question_type_dropdown {
      .dropdown-item {
        &:hover {
          color: $primary;
          background: rgba(0, 82, 158, 0.1);
        }
        &:active {
          color: $primary;
          background: rgba(0, 82, 158, 0.1);
        }
      }
    }
    .badge_paid {
      background-color: rgba(0, 82, 158, 0.18) !important;
      color: rgb(0, 82, 158);
    }

    .add-section-div {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        display: block;
        content: "";
        width: 85%;
        height: 1px;
        background-color: rgba(112, 112, 112, 0.2);
      }
      span {
        background-color: rgba(112, 112, 112, 0.4);
      }
    }

    .question-type-card {
      color: gray;
      border: 1px solid #d3d2d2;
      .card-header {
        background-color: $gray-500;
        border-top: 0px solid #d3d2d2;
        border-bottom: 1px solid #d3d2d2;

        font-weight: 700;
      }
      .sideBarItem {
        margin: 0; //0.5rem 0.2rem
        padding: 0.6rem 0.5rem 0.6rem 0.5rem;
        background-color: #ffffff; //$gray-100
        text-align: center;
        // box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
        // -webkit-box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
        // -moz-box-shadow: 0px 0px 1px 0.3px rgba(135, 135, 135, 0.75);
        // border-top:1px solid #d3d2d2;
        // border-bottom: 1px solid #d3d2d2;
        i {
          font-size: 17px;
        }
        .add-type-btn {
          border: 0px;
          background: #e5e5eb;
          padding: 4px 15px;
          visibility: hidden;
        }
      }
      .active {
        cursor: pointer;
        background-color: rgba(192, 192, 192, 0.26);

        .add-type-btn {
          visibility: visible;
        }
        i.bxs-edit {
          &::before {
            content: "" !important;
            border: 1px solid #fff;
            width: 13px;
            height: 5px;
            display: inherit;
          }
        }
      }
      .sideBarItem {
        &:hover {
          cursor: pointer;
          background-color: rgba(192, 192, 192, 0.26);

          .add-type-btn {
            visibility: visible;
          }
          i.bxs-edit {
            &::before {
              content: "" !important;
              border: 1px solid #fff;
              width: 13px;
              height: 5px;
              display: inherit;
            }
          }
        }
      }
    }
    .question_ai_card {
      color: gray;
      .limit-span {
        color: rgba(128, 128, 128, 0.5);
        top: 24.8%;
        right: 8%;
      }
      .badge {
        background-color: rgba(122, 223, 183, 0.54) !important;
        color: rgb(43, 154, 109);
      }
      .input-group {
        input {
          color: gray !important;
          background-color: rgb(255, 255, 255) !important;
          font-size: 0.813rem !important;
        }
        .input-group-text {
          color: gray;
          &:first-of-type {
            font-size: 0.875rem !important;
          }
        }
      }
      .nav-tabs {
        .active {
          cursor: pointer;
          background-color: #fff;
          color: #242424;

          .add-type-btn {
            visibility: visible;
          }
          i.bxs-edit {
            &::before {
              content: "" !important;
              border: 1px solid #fff;
              width: 13px;
              height: 5px;
              display: inherit;
            }
          }
        }
      }
    }
    .question-card-view {
      color: gray;
      .card-header {
        background-color: rgb(239, 239, 239);
      }
    }
  }
  .custom-nav-survey {
    position: inherit;
  }
  .creation-main-heading {
    font-size: 18px;
    font-weight: normal;
  }
  .creation-container {
    height: calc(100vh - 91px);
  }
  .survey-category-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .survey-category-details {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
// .step-arrow-nav .nav .nav-link.active {
// 	background-color: #405189;
// }
// .step-arrow-nav .nav .nav-link.active::before {
// 	border-left-color: #405189;
// }
.survey-input {
  border: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  padding-left: 0px;
}

.link-disabled {
  pointer-events: none;
}

.new-survey-simplebar {
  height: 50vh;

  @media screen and (min-height: 700px) {
    height: 70vh;
  }
}

.section_wised_view {
  .side_question_box {
    &:hover {
      background-color: rgba(0, 82, 158, 0.1);
    }
  }
  .active {
    background-color: rgba(0, 82, 158, 0.1);
  }
}
