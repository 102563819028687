//
// _team.scss
//

//Bookmark
.bookmark-icon {
  svg {
    fill: transparent;
  }

  .btn-star {
    cursor: pointer;
  }

  .bookmark-input {
    &:checked {
      & ~ .btn-star svg {
        fill: $warning !important;
        color: $warning !important;
      }
    }
  }
}

.bookmark-hide {
  display: none;
  visibility: hidden;
  height: 0;
}

.team-box,
.profile-offcanvas {
  .team-cover {
    display: none;
    position: relative;
    margin-bottom: -140px;

    img {
      height: 140px;
      width: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, darken($primary, 25%), $primary);
      opacity: 0.6;
    }
  }
}

.team-list {
  &.grid-view-filter {
    flex-flow: row wrap;

    .col {
      flex: 0 0 auto;
      width: 25%;
    }

    .team-box {
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      cursor: pointer;
      .team-row {
        align-items: start;

        .col {
          width: 100%;
        }
      }

      .team-cover {
        display: block;
      }

      .team-settings {
        .col {
          width: 50% !important;
          flex: 0 0 auto;
        }

        .btn-star {
          color: $white;
        }

        .dropdown {
          > a {
            color: $white;
          }
        }
      }

      .team-profile-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 25px;
        margin: 17px;
        // margin-top: 0px;

        .avatar-lg {
          font-size: 22px;
        }

        .team-content {
          margin-left: 0px;
          //   margin-top: 25px;
          text-align: center;
          h5 {
            height: 40px;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .view-btn {
        width: 100%;
        margin-top: 25px;
      }
      &:hover {
        box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
        -webkit-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
        -moz-box-shadow: 0px 5px 12px 0px rgba(112, 115, 117, 0.34);
        transform: scale(1.05);
      }
    }

    @media (min-width: 1200px) and (max-width: 1399.98px) {
      .col {
        flex: 0 0 auto;
        width: 33.33%;
      }
    }

    @media (max-width: 1199.98px) {
      .col {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    @media (max-width: 767.98px) {
      .col {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }

  &.list-view-filter {
    flex-direction: column;

    .team-box {
      margin-bottom: 10px;

      .team-row {
        align-items: center;
        justify-content: space-between;
      }

      .team-profile-img {
        display: flex;
        align-items: center;

        .avatar-lg {
          height: 4rem;
          width: 4rem;
          font-size: 16px;
        }

        .team-content {
          margin-left: 15px;
        }
      }

      .team-settings {
        width: auto;
        flex: 0 0 auto;
        -webkit-box-ordinal-group: 7;
        order: 6;
      }

      .btn-star {
        color: $primary;
      }
    }

    @media (max-width: 767.98px) {
      flex-direction: row;
      .col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
      }
      .team-box {
        .team-settings {
          width: 100%;
          flex: 0 0 auto;
          order: -1;
          margin-bottom: 10px;

          .col {
            width: 50%;
          }
        }

        .team-profile-img {
          margin: 25px;
          // margin-bottom: 25px;
        }

        .view-btn {
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
}

.list-grid-nav {
  .nav-link {
    &.active {
      background-color: $info;
      color: $white;
    }
  }
}

.profile-offcanvas {
  .team-cover {
    margin-bottom: -132px;
    display: block;
    z-index: -1;
  }
  .btn-star {
    color: $white;
  }

  .dropdown {
    > a {
      color: $white;
    }
  }
}
