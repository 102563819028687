.dropZone {
  transition: 200ms all;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #e5e5eb; /* rgb(232, 232, 232) */
  margin-bottom: 1rem;
  padding: 0 15px; /* 0.5rem 0rem */
  border-radius: 0px; /* 0.3rem */
}

.dropzone_message_div {
  width: 80%;
  height: 100px;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
.dropzone_selected_message {
  background-color: #cff4fc;
  border-radius: 1rem;
  width: 80%;
  margin: auto auto 1rem auto;
  padding: 0.3rem;
}
