.survey-settings {
	overflow-y: hidden;
	& > .card {
		margin-bottom: 0%;
		height: 84.5vh;
	}
	.survey-format-card:checked {
		background-color: red;
	}
	.survey-format-card {
		i {
			position: relative;
			top: -41px;
			background-color: #ffffff;
			color: $primary;
			width: 42px;
			padding: 0;
			margin: 0;
			height: 42px;
			box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
			-webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
		}
		// .card-body {
		// }
		.survey-format-radio {
			border: none;
			width: 0%;
			height: 0%;
			margin-top: 0%;
			&:checked {
				background: transparent;
				border-color: transparent;
			}
		}
		// .survey-format-radio:checked ~ .card-body {
		// 	transition: background-color 1s;
		// 	background-color: #405189;
		// }
		// .survey-format-radio:checked ~ .card-body {
		// 	h5,
		// 	h6,
		// 	p {
		// 		color: #ffffff !important;
		// 	}
		// }
	}
}

.recurrence_model {
	.pagination {
		margin-bottom: 0px;
		li {
			width: 14.28%;
			button {
				width: 100%;
			}
		}
	}
	.flatpickr-wrapper {
		width: 100%;
	}
	.monthly_day_picker + .flatpickr-calendar {
		.flatpickr-months {
			display: none !important;
		}
		.flatpickr-weekdays {
			display: none !important;
		}
		.flatpickr-day.prevMonthDay,
		.flatpickr-day.nextMonthDay {
			display: none !important;
		}
		.flatpickr-day.today {
			border-color: transparent;
			box-shadow: none;
			background-color: transparent;
			// display: none !important;
		}
	}

	.survey-format-card {
		.survey-format-radio {
			border: none;
			width: 0%;
			height: 0%;
			margin-top: 0%;
			&:checked {
				background: transparent;
				border-color: transparent;
			}
		}
		// .survey-format-radio:checked ~ .card-body {
		// 	transition: background-color 1s;
		// 	background-color: #405189;
		// }
		.survey-format-radio:checked ~ .card-body {
			h5,
			h6,
			p {
				color: #ffffff !important;
			}
		}
	}
}

.onCheck_SurveyBox {
	background-color: $primary;
	h5,
	h6,
	p {
		color: #ffffff !important;
	}
}
